var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { sm: "6", xl: "4" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", { staticClass: "pb-0 border-bottom-0" }, [
                    _c(
                      "h5",
                      { staticClass: "card-title mb-0" },
                      [
                        _c("CIcon", { attrs: { name: "cil-bolt" } }),
                        _vm._v(" My Tasks Progress"),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "CCardBody",
                    { staticClass: "top-card-body" },
                    [
                      _c("CChartDoughnut", {
                        attrs: {
                          datasets: _vm.taskChartDatasets,
                          labels: _vm.taskChartLabels,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", xl: "8" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", { staticClass: "pb-0 border-bottom-0" }, [
                    _c(
                      "h5",
                      { staticClass: "card-title mb-0" },
                      [
                        _c("CIcon", { attrs: { name: "cil-note-add" } }),
                        _vm._v(" Memo"),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "CCardBody",
                    { staticClass: "top-card-body" },
                    [_c("Memo")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-bolt" } }),
              _c("h5", { staticClass: "d-inline ml-2" }, [
                _vm._v(
                  "My Open Tasks (" + _vm._s(_vm.myPendingTasks.length) + ")"
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c(
                    "CButton",
                    { attrs: { color: "primary", to: { name: "Task List" } } },
                    [
                      _c("CIcon", { attrs: { name: "cil-bolt", size: "lg" } }),
                      _vm._v(" Show "),
                      _c("strong", [_vm._v("All Tasks")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("TaskTable", {
                attrs: { tasks: _vm.myPendingTasks, loading: _vm.loading },
                on: { taskChanged: _vm.getMyTasks },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-bell-exclamation" } }),
              _c("h5", { staticClass: "d-inline ml-2" }, [_vm._v("Issues")]),
            ],
            1
          ),
          _c("CCardBody", [
            _c(
              "div",
              _vm._l(_vm.issues, function (issue) {
                return _c(
                  "CCard",
                  { staticClass: "mb-0" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "text-left shadow-none card-header",
                        attrs: { block: "", color: "link" },
                        on: {
                          click: function ($event) {
                            _vm.accordion =
                              _vm.accordion === issue.id ? false : issue.id
                          },
                        },
                      },
                      [
                        _c("h5", { staticClass: "m-0" }, [
                          _vm._v("• " + _vm._s(issue.title)),
                        ]),
                      ]
                    ),
                    _c(
                      "CCollapse",
                      { attrs: { show: _vm.accordion === issue.id } },
                      [
                        _c(
                          "CCardBody",
                          { staticClass: "p-1" },
                          [
                            _c(issue.component, {
                              tag: "component",
                              attrs: { active: _vm.accordion === issue.id },
                              on: {
                                "update:active": function ($event) {
                                  return _vm.$set(
                                    _vm.accordion === issue,
                                    "id",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }