var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { autocomplete: "off" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.addItem.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "memo" },
        [
          _c("CTextarea", {
            staticClass: "memo-input",
            attrs: { rows: "2" },
            model: {
              value: _vm.newItem,
              callback: function ($$v) {
                _vm.newItem = $$v
              },
              expression: "newItem",
            },
          }),
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-add",
              attrs: { disabled: _vm.newItem === "" },
            },
            [_c("CIcon", { attrs: { name: "cil-plus" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "memo-list" },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "memo-list-item",
              class: { completed: item.completed },
            },
            [
              _c("CInputCheckbox", {
                attrs: {
                  label: item.note,
                  custom: "",
                  checked: item.completed,
                },
                on: {
                  "update:checked": [
                    function ($event) {
                      return _vm.$set(item, "completed", $event)
                    },
                    function ($event) {
                      return _vm.updateItem(item)
                    },
                  ],
                },
              }),
              _c(
                "CButton",
                {
                  staticClass: "btn-delete",
                  attrs: {
                    size: "sm",
                    color: "danger",
                    variant: "ghost",
                    shape: "pill",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteItem(item, index)
                    },
                  },
                },
                [_c("CIcon", { attrs: { name: "cil-trash" } })],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }