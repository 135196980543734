var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c("CCardHeader", { staticClass: "pb-0 border-bottom-0" }, [
        _c(
          "h4",
          { staticClass: "d-flex align-items-center flex-gap-2" },
          [
            _c("CIcon", { attrs: { name: "cil-chart-line", size: "2xl" } }),
            _vm._v(" Revenue Report "),
          ],
          1
        ),
      ]),
      _c("CCardBody", [
        _vm.revenue
          ? _c(
              "div",
              {
                staticClass: "row mt-3",
                staticStyle: { "font-size": "1.15rem" },
              },
              [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("p", [
                    _c("strong", [_vm._v("Month:")]),
                    _vm._v(
                      " " + _vm._s(_vm._f("ucfirst")(_vm.revenue.dates.month))
                    ),
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("Start Date:")]),
                    _vm._v(" " + _vm._s(_vm.revenue.dates.startDate)),
                  ]),
                  _c("p", [
                    _c("strong", [_vm._v("End Date:")]),
                    _vm._v(" " + _vm._s(_vm.revenue.dates.endDate)),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6 h3" }, [
                  _c("p", [
                    _c("strong", [_vm._v("Total Revenue:")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("toCurrency")(
                            parseFloat(_vm.revenue.totalRevenue)
                          )
                        )
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "CElementCover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { opacity: 0.4 },
        },
        [
          _c("CSpinner", {
            attrs: { size: "5xl", grow: "", color: "primary" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }