var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column flex-md-row align-items-end justify-content-between mb-1",
            },
            [
              _c("h5", { staticClass: "text-warning p-2 mb-0" }, [
                _vm.stats?.difference > 0
                  ? _c("span", [
                      _vm._v(
                        "🛈 Compared to the previous year, " +
                          _vm._s(_vm.stats.difference) +
                          " fewer holidays have been scheduled for this year."
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "CButton",
                { attrs: { color: "primary", to: { name: "Holidays" } } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-header" },
                  }),
                  _vm._v(" "),
                  _c("strong", [_vm._v("View")]),
                  _vm._v(" Holidays "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.items.length > 0
        ? _c("CDataTable", {
            staticClass: "issue-table table-outline",
            attrs: {
              hover: "",
              items: _vm.items,
              fields: _vm.fields,
              loading: _vm.loading,
              noItemsView: {
                noResults: "No filtering results available!",
                noItems: "No holidays found!",
              },
              "head-color": "light",
              "no-sorting": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "name",
                  fn: function ({ item }) {
                    return [
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-items-center flex-gap-2",
                          },
                          [
                            _c("CIcon", {
                              attrs: {
                                content:
                                  _vm.$options.flagSet[item.country?.flag],
                                size: "lg",
                              },
                            }),
                            _c("strong", [
                              _vm._v(_vm._s(item.holiday_name?.name)),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "start_date",
                  fn: function ({ item }) {
                    return [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.moment(item.start_date).format("LL"))
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "end_date",
                  fn: function ({ item }) {
                    return [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            item.end_date
                              ? _vm.moment(item.end_date).format("LL")
                              : ""
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              4042069784
            ),
          })
        : _c(
            "div",
            [
              _vm.loading
                ? _c("CSpinner")
                : _c("h5", { staticClass: "text-warning p-2 mb-0" }, [
                    _vm._v(
                      " ⚠ There are no scheduled holidays for the next six months! "
                    ),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }