var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.restrictedUser
    ? _c("DashboardRestricted")
    : _vm.authUser
    ? _c("Dashboard")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }