var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.items.length > 0
        ? _c("CDataTable", {
            staticClass: "issue-table table-outline",
            attrs: {
              hover: "",
              items: _vm.items,
              fields: _vm.fields,
              loading: _vm.loading,
              noItemsView: {
                noResults: "No filtering results available!",
                noItems: "No issue found!",
              },
              "head-color": "light",
              "no-sorting": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "restaurant",
                  fn: function ({ item }) {
                    return _c("td", {}, [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "div",
                          { staticClass: "c-avatar mr-2" },
                          [
                            _c("CImg", {
                              staticClass: "c-avatar-img",
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: item.restaurant_logo_path,
                                placeholderColor: "lightgray",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "CLink",
                              {
                                staticClass: "text-muted",
                                attrs: {
                                  to: {
                                    name: "View Restaurant",
                                    params: { id: item.id },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.restaurant_name))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  },
                },
                {
                  key: "location",
                  fn: function ({ item }) {
                    return _c("td", {}, [
                      _c("strong", [_vm._v(_vm._s(item.restaurant_city))]),
                      _vm._v(" "),
                      _c("small", [_vm._v(_vm._s(item.restaurant_postcode))]),
                    ])
                  },
                },
                {
                  key: "orders",
                  fn: function ({ item }) {
                    return _c(
                      "td",
                      {},
                      [
                        _c(
                          "CLink",
                          {
                            staticClass: "text-muted",
                            attrs: {
                              to: {
                                name: "RestaurantOrderList",
                                params: { restaurant_id: item.id },
                              },
                            },
                          },
                          [_vm._v("View Orders")]
                        ),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "fix",
                  fn: function ({ item }) {
                    return _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: {
                                  content: "View Restaurant",
                                  placement: "top",
                                },
                                expression:
                                  "{\n          content: 'View Restaurant',\n          placement: 'top',\n        }",
                              },
                            ],
                            attrs: {
                              to: {
                                name: "View Restaurant",
                                params: { id: item.id },
                              },
                              size: "sm",
                              color: "primary",
                              variant: "ghost",
                              shape: "pill",
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-restaurant" } })],
                          1
                        ),
                      ],
                      1
                    )
                  },
                },
              ],
              null,
              false,
              720051963
            ),
          })
        : _c(
            "div",
            [
              _vm.loading
                ? _c("CSpinner")
                : _c("h6", [_vm._v("No issue found!")]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }